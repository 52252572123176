<template>
  <div class="fTable">
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="data.columns"
      :size="size"
      :border="border"
      highlight-current-row
      :height="height"
      :max-height="height || maxHeight"
      :cell-style="data.cellStyle"
      :row-class-name="data.tableRowClassName"
      :header-cell-style="headerCellStyle"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      @row-dblclick="dblclick"
      @row-click="rowclick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="data.mutiCheck" :fixed="data.mutiFixed" :selectable="data.selected" align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column
        v-if="data.showIndex"
        align="center"
        type="index"
        :width="data.indexWidth || 50">
        <template slot="header">
          <div :class="['fp-cell', !border && 'fp-cell-b']">序号</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="data.showRadio"
        align="center"
        width="50">
        <template slot="header">
          <div :class="['fp-cell', !border && 'fp-cell-b']">选择</div>
        </template>
        <template slot-scope="scope">
          <el-radio v-model="radio" :label="scope.$index" @input="handlerRadio">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <template v-for="(item, index) in data.fileds">
        <el-table-column
          v-if="!item.isCustom"
          :key="index"
          :align="item.align||'center'"
          :resizable="item.resizable || false"
          :show-overflow-tooltip="showTooltip"
          :prop="item.filed"
          :label="item.label"
          :width="item.width"
          :min-width="item.minWidth"
          :fixed="item.fixed"
          :formatter="item.formatter"
        >
          <template slot="header" slot-scope="scope">
            <div :class="['fp-cell', !border && 'fp-cell-b']">
              {{ scope.column.label }}
              <span v-if="item.isCopy" @click="copy(scope.column)">
                <svg-icon title="复制整列" iconClass="copy" className="icon" />
              </span>
              <template v-if="item.isSort">
                <span class="sort">
                  <i class="sort-caret asc" :class="{'asc-active': sortObj[item.filed] === 'asc'}" @click.stop="sortChange(item.filed, 'asc')"></i>
                  <i class="sort-caret desc" :class="{'desc-active': sortObj[item.filed] === 'desc'}" @click.stop="sortChange(item.filed, 'desc')"></i>
                </span>
              </template>
              <slot :name="`${item.filed}-header`"></slot>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="`${index}`"
          :align="item.align||'center'"
          :resizable="item.resizable || false"
          :show-overflow-tooltip="showTooltip"
          :prop="item.filed"
          :label="item.label"
          :width="item.width"
          :min-width="item.minWidth"
          :fixed="item.fixed"
          :formatter="item.formatter"
        >
          <template slot="header" slot-scope="scope">
            <div :class="['fp-cell', !border && 'fp-cell-b']">
              {{ scope.column.label }}
              <span v-if="item.isCopy" @click="copy(scope.column)">
                <svg-icon title="复制整列" iconClass="copy" className="icon" />
              </span>
              <template v-if="item.isSort">
                <span class="sort">
                  <i class="sort-caret asc" :class="{'asc-active': sortObj[item.filed] === 'asc'}" @click.stop="sortChange(item.filed, 'asc')"></i>
                  <i class="sort-caret desc" :class="{'desc-active': sortObj[item.filed] === 'desc'}" @click.stop="sortChange(item.filed, 'desc')"></i>
                </span>
              </template>
              <template v-if="item.tip">
                <el-tooltip
                  :content="item.tipContent"
                  effect="dark"
                  :tabindex="2000"
                  placement="top"
                >
                  <img style="margin-left: 5px;" src="@/assets/images/table/question.png" alt="">
                </el-tooltip>
              </template>
              <slot :name="`${item.filed}-header`"></slot>
            </div>
          </template>
          <template slot-scope="scope">
            <slot
              :name="item.filed"
              :scope="scope.row"
              :tIndex="scope.$index"
            ></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <slot name="custom-total"></slot>
    <div v-if="isPager" class="pager">
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[20, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../icon/SvgIcon.vue'
import { mapGetters } from 'vuex'
export default {
  components: { SvgIcon },
  props: {
    tableData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    height: {
      type: [Number, String],
      default: 600
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageFooter: {
      type: Boolean,
      default: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium'
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    isPager: {
      type: Boolean,
      default: true
    },
    selectRows: {
      type: Array,
      default: function() {
        return []
      }
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    showRadio: {
      type: Boolean,
      default: false
    },
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          background: '#F7F7F7',
          color: '#333333',
          fontWeight: '400'
        }
      }
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    getSummaries: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      radio: '',
      sortObj: {
      }, // undefined 不排序 1： 升序 2： 降序
      lastTwoWidth: ''
    }
  },
  computed: {
    ...mapGetters(['isCollapse']),
    data: function() {
      const tableData = this.tableData
      return tableData
    }
  },
  watch: {
    selectRows: function(items) {
      if (items && items.length > 0) {
        items.forEach((row) => {
          const rowIndex = this.data.columns.findIndex((a) => {
            return JSON.stringify(a) === JSON.stringify(row)
          })
          this.$refs.multipleTable.toggleRowSelection(
            this.data.columns[rowIndex]
          )
        })
      }
    },
    'tableData.fileds': {
      handler: function(fileds) {
        this.$nextTick(() => {
          this.setFileds(fileds, this.isCollapse ? 100 : 200)
        })
      },
      deep: true,
      immediate: true
    },
    isCollapse: {
      handler: function(v) {
        this.$nextTick(() => {
          this.setFileds(this.tableData.fileds, v ? 100 : 200)
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const fileds = this.tableData.fileds
    this.lastTwoWidth = fileds?.length > 1 ? fileds[fileds.length - 2]?.width : 0
    window.addEventListener('resize', () => {
      this.$refs.multipleTable?.doLayout()
      this.setFileds(fileds, this.isCollapse ? 100 : 200)
    })
  },
  updated() {
    if (this.$refs.multipleTable && this.$refs.multipleTable?.doLayout) {
      this.$refs.multipleTable?.doLayout()
    }
    this.$nextTick(() => {
      this.$refs['multipleTable']?.doLayout()
    })
  },
  methods: {
    setFileds(fileds, navWidth) {
      if (fileds && fileds.length > 0) {
        const flag = fileds && fileds.find(a => a.width === '')
        // 如果存在宽度为 ''的 则不需要适配 自适应
        if (!flag && fileds) {
          const maxWidth = document.body.clientWidth - navWidth // 左侧菜单栏宽度 + 中间区域边距
          let filedsSumWidth = 0
          fileds.forEach(item => {
            if (item.width) {
              if (item.width.toString().indexOf('px') > -1) {
                filedsSumWidth += parseFloat(item.width.split('px')[0], 10)
              } else {
                filedsSumWidth += parseFloat(item.width, 10)
              }
            } else {
              filedsSumWidth += 80
            }
          })
          if (filedsSumWidth < maxWidth) {
            if (fileds.length - 2 > 0) {
              fileds && (fileds[fileds.length - 2].width = '')
              fileds && (fileds[fileds.length - 2].minWidth = this.lastTwoWidth)
            }
          }
        }

        this.$refs.multipleTable?.doLayout()
      }
    },
    handleSizeChange(val) {
      this.$emit('handlePageChange', {
        pageIndex: 1,
        pageSize: val || this.pageSize
      })
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    handleCurrentChange(val) {
      // 可能没有数据 导致重置当前页为0 再次发起请求
      if (val) {
        this.$emit('handlePageChange', {
          pageIndex: val,
          pageSize: this.pageSize
        })
        this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      }
    },
    dblclick(row, column) {
      this.$emit('dblclick', row, column)
    },
    rowclick(row) {
      this.$emit('rowclick', row)
    },
    handlerRadio(index) {
      console.log(index)
      this.$emit('handlerRadio', this.tableData.columns[index])
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    sortChange(filed, sort) {
      console.log('filed', filed)
      const obj = this.sortObj || {}
      if (obj[filed] === sort) {
         delete obj[filed]
      } else {
        obj[filed] = sort
      }
      this.sortObj = {
        ...obj
      }
      this.$emit('sortChange', {
        prop: filed,
        order: obj[filed]
      })
    },
    copy(column) {
      let str = ''
      this.data.columns.map((item) => {
        str += `${item[column.property]}\n`
      })
      const that = this
      this.$copyText(str).then(
        function(e) {
          console.log('copy arguments e:', e)
          that.$message.success('复制成功!')
        },
        function(e) {
          console.log('copy arguments e:', e)
          that.$message.error('复制失败!')
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.fTable {
  // width: calc(100% - 20px);
  position: relative;
  width: 100%;
  height: auto;
  .pager {
    display: flex;
    justify-content: flex-end;
    height: 42px;
    align-items: center;
    z-index: 999;
    position: relative;
  }

  .fp-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    &.fp-cell-b {
      border-right: 1px solid rgba(219, 228, 242, 1);
    }
    .sort {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      height: 20px;
      width: 24px;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      position: relative;
    }
    .asc {
      border-bottom-color: #c0c4cc;
      top: -2px;
    }
    .desc {
      border-top-color: #c0c4cc;
      bottom: 0px;
    }
    .icon {
      cursor: pointer;
      color: #2395f3;
      font-size: 20px;
      // visibility: hidden;
    }
    .desc-active {
      border-top-color: #35BA83;
    }
    .asc-active {
      border-bottom-color: #35BA83;
    }
    // &:hover .icon {
    //   visibility: visible;
    // }
  }
  /deep/ .el-table__fixed-right-patch {
    background: #f7f7f7;
  }
  /deep/ .el-table__cell.gutter {
    background: #f7f7f7;
  }
}
</style>
